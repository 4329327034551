import React from 'react';
import Layout from '../shared/Layout/Layout';
import Privacy from '../shared/Privacy/Privacy';
import { useStrapiRequest } from '../customHooks/useStrapiRequest';
import data from '../pages-fsd/PrivacyPolicyPage/model/privacy-policy.data';
import HeadMaker from '../HeadMaker';

export function Head(props) {
  return <HeadMaker {...props} seo={data.seo} />;
}

function PrivacyPolicyPage() {
  const privacyPolicy = useStrapiRequest('agreements/3');
  const privacyPolicyText = privacyPolicy[0];

  return (
    <Layout leadPage="Политика конфиденциальности">
      {!!privacyPolicyText && (
        <Privacy bg="privacy" privacyPolicyText={privacyPolicyText} />
      )}
    </Layout>
  );
}

export default PrivacyPolicyPage;
