export const styles = {
  header: (theme) => ({
    color: 'kit.text.main',
    fontSize: '46px',
    marginTop: '40px',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: '34px',
      marginTop: '14px',
    },
  }),
  markdownText: (theme) => ({
    '& > div > ol': {
      paddingLeft: '17px',
    },
    '& > div, p, li': {
      color: 'kit.text.main',
      fontSize: '16px',
      fontFamily: 'Inter',
      marginBottom: '24px',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    },
    '& > .MuiTypography-desktopH1': {
      color: 'kit.text.main',
      fontSize: '46px',
      [theme.breakpoints.down('md')]: {
        fontSize: '34px',
      },
    },
    '& > .MuiTypography-desktopH2': {
      color: 'kit.text.main',
      fontSize: '34px',
      marginTop: '42px',
      marginBottom: '24px',
      [theme.breakpoints.down('md')]: {
        fontSize: '22px',
        marginTop: '32px',
        marginBottom: '16px',
      },
    },
    '& > .MuiTypography-desktopH3': {
      color: 'kit.text.main',
      fontSize: '18px',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
      },
    },
  }),
};
