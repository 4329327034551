import React from 'react';
import { Box, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styles, { createBgTheme } from './styles';

function SectionBg({
  children,
  colorTheme,
  bg,
  size,
  bgImgs,
  position = 'top',
  customStyles,
  withoutBg = false,
}) {
  const theme = useTheme();
  const isWidthUpperXXL = useMediaQuery(theme.breakpoints.up('xxl'));
  let xxlImage;
  if (bg && isWidthUpperXXL && `backgrounds/${bg}-desktop-large` in bgImgs) {
    xxlImage = bgImgs[`backgrounds/${bg}-desktop-large`];
  }

  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <Box
      component="section"
      sx={{
        ...styles.section,
        ...createBgTheme(colorTheme, size),
        ...customStyles,
      }}
    >
      {!withoutBg && (
        <Box sx={styles.bg}>
          {window.isMobile && `backgrounds/${bg}-mobile` in bgImgs && (
            <GatsbyImage
              style={{ ...styles.imageBlock, [position]: 0 }}
              imgStyle={styles.image}
              image={getImage(bgImgs[`backgrounds/${bg}-mobile`])}
              alt=""
              loading="lazy"
            />
          )}
          {!window.isMobile &&
            (xxlImage || bgImgs[`backgrounds/${bg}-desktop`]) &&
            (xxlImage ? (
              <GatsbyImage
                style={{ ...styles.imageBlock, [position]: 0 }}
                imgStyle={styles.image}
                image={getImage(xxlImage)}
                alt=""
                loading="lazy"
              />
            ) : (
              <GatsbyImage
                style={{ ...styles.imageBlock, [position]: 0 }}
                imgStyle={styles.image}
                image={getImage(bgImgs[`backgrounds/${bg}-desktop`])}
                alt=""
                loading="lazy"
              />
            ))}
        </Box>
      )}
      {children}
    </Box>
  );
}

export default SectionBg;
