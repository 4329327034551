export default {
  seo: {
    title: 'Политика конфиденциальности школы программирования Эльбрус Буткемп',
    meta: [
      {
        property: null,
        name: 'description',
        content:
          'Мы ценим конфиденциальность наших клиентов и соблюдаем все необходимые правила и меры защиты данных. Узнайте больше о нашей политике конфиденциальности.',
      },
      { property: 'og:type', name: null, content: 'product' },
      {
        property: 'og:title',
        name: null,
        content:
          'Политика конфиденциальности школы программирования Эльбрус Буткемп',
      },
      {
        property: 'og:description',
        name: null,
        content:
          'Мы ценим конфиденциальность наших клиентов и соблюдаем все необходимые правила и меры защиты данных. Узнайте больше о нашей политике конфиденциальности.',
      },
      {
        property: 'og:image',
        name: null,
        content:
          'https://elbrusboot.camp/static/9ec3bbdb5710d3a526e35a29049baa78/f85ce/slide-3.jpg',
      },
      {
        property: 'og:site_name',
        name: null,
        content: 'Эльбрус Буткемп',
      },
      { property: 'og:locale', name: null, content: 'ru_RU' },
      { property: 'fb:app_id', name: null, content: '581051622277135' },
      { property: 'twitter:card', name: null, content: 'summary_large_image' },
      {
        property: 'twitter:site',
        name: null,
        content: '@elbrus_bootcamp',
      },
      {
        property: 'twitter:image:src',
        name: null,
        content:
          'https://elbrusboot.camp/static/9ec3bbdb5710d3a526e35a29049baa78/f85ce/slide-3.jpg',
      },
    ],
  },
};
