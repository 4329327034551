import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import SectionBg from './SectionBg';
import { useBackgroundsQuery } from '../../app/queries/backgrounds.query';

import styles, { createTitleColorTheme } from './styles';

function Section({
  children,
  title,
  theme = 'default',
  size = 'default',
  customStyles = {},
  wrapperStyles = {},
  containerStyles = {},
  bg,
  position,
  anchor = null,
  withoutBg = false,
  flexGrow = false,
  titleSx,
  titleH1 = false,
  Bullet,
}) {
  const { bgImgs } = useBackgroundsQuery();

  return (
    <Box
      key={`section-${title}`}
      sx={{ ...styles.wrapper, ...wrapperStyles, flexGrow: flexGrow && 1 }}
    >
      <Box
        component="p"
        key={anchor}
        id={anchor}
        sx={{ top: '-59px', position: 'relative', visibility: 'hidden' }}
      />
      <SectionBg
        size={size}
        bg={bg}
        colorTheme={theme}
        bgImgs={bgImgs}
        position={position}
        customStyles={customStyles}
        withoutBg={withoutBg}
      >
        <Container
          maxWidth="xl"
          sx={{ ...styles.container, ...containerStyles }}
        >
          <Box sx={titleSx}>
            {/* {subtitle && (
              <Typography
                variant="desktopH5"
                sx={{
                  ...createSubtitleColorTheme(theme),
                }}
              >
                {`<${subtitle}>`}
              </Typography>
            )} */}
            <Box sx={{ display: { xs: 'block', lg: 'flex' } }}>
              {Bullet && (
                <Box sx={{ py: 1.5, mr: 2 }}>
                  <Bullet />
                </Box>
              )}
              {title && (
                <Typography
                  variant="desktopH2"
                  sx={{
                    ...styles.title,
                    ...createTitleColorTheme(theme),
                  }}
                  {...(titleH1 ? { component: 'h1' } : {})}
                  dangerouslySetInnerHTML={{
                    __html: Array.isArray(title) ? title.join('<br />') : title,
                  }}
                />
              )}

              {/* {title && (
                <Typography
                  variant="desktopH2"
                  sx={{
                    ...styles.title,
                    ...createTitleColorTheme(theme),
                  }}
                  {...(titleH1 ? { component: 'h1' } : {})}
                >
                  {Array.isArray(title)
                    ? title.map((item) => (
                        <Typography key={item}>{item}</Typography>
                      ))
                    : title}
                </Typography>
              )} */}
            </Box>
          </Box>
          {children}
        </Container>
      </SectionBg>
    </Box>
  );
}

export default Section;
