import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Section from '../Section/Section';
import { styles } from './styles';
import Markdown from '../Markdown/Markdown';

function Privacy({ bg, privacyPolicyText }) {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return (
    <Section bg={bg}>
      <Box>
        <Typography
          sx={styles.header}
          variant={isSm ? 'mobileH2' : 'desktopH2'}
        >
          Политика конфиденциальности
        </Typography>
        <Box sx={styles.markdownText}>
          <Markdown variant={isSm ? 'mobileP2' : 'desktopP2'}>
            {privacyPolicyText?.text}
          </Markdown>
        </Box>
      </Box>
    </Section>
  );
}

export default Privacy;
