import { useStaticQuery, graphql } from 'gatsby';
import { getImageDataMapByFileEdges } from '../../helpers/lib';

export const useBackgroundsQuery = () => {
  const { allFile } = useStaticQuery(graphql`
    query backgroundsQuery {
      allFile(filter: { relativeDirectory: { regex: "/backgrounds/" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
            relativeDirectory
            name
            absolutePath
          }
        }
      }
    }
  `);

  const bgImgs = getImageDataMapByFileEdges(allFile.edges);
  return { bgImgs };
};
