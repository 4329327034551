import { useState, useEffect } from 'react';

export const ResponseStatusEnum = {
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
};

const { LOADING, LOADED, ERROR } = ResponseStatusEnum;

/**
 * Custom hook to fetch strapi collection data
 * @param {string} collection - name of a collection in strapi
 * @param {*} initialState - to be returned if error occurs (null by default)
 * @returns {[*, boolean, string]} Array of 3 elements: data, loading state, status
 */
export const useStrapiRequest = (collection, initialState = null) => {
  const [data, setData] = useState(initialState);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(LOADING);

  const strapiUrl = `${process.env.GATSBY_API_URL}/${collection}`;

  const getData = async () => {
    try {
      const response = await fetch(strapiUrl);

      if (response.status === 200) {
        const responseData = await response.json();
        setData(responseData);
      }

      setStatus(LOADED);
    } catch (error) {
      setStatus(ERROR);
    }

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return [data, loading, status];
};
